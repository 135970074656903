<template>
  <div>
    <template>
      <v-container>
        <v-row justify="center" align="center">
          <v-col cols="12">
            <Subheader icon="mdi-card-text" title="Allgemein"></Subheader>
            <div class="mt-7">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model.trim="courseLocal.title"
                          :counter="32"
                          label="Bezeichnung"
                          required
                          :rules="[rules.required]"
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-select
                          v-model.trim="courseLocal.group"
                          :items="courseGroups"
                          item-text="title"
                          label="Lehrgangsgruppe"
                          required
                          return-object
                          :rules="[rules.required]"
                          outlined
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-select
                          v-model.trim="courseLocal.type"
                          label="Lehrgangsart"
                          :items="courseTypeOptions"
                          required
                          :rules="[rules.required]"
                          outlined
                        ></v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-textarea
                          v-model.trim="courseLocal.description"
                          label="Kurzbeschreibung"
                          auto-grow
                          :rows="1"
                          required
                          outlined
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </div>
</template>

<script>
import Subheader from "@/components/_systemwide/Subheader.vue";
import { MEMBER_MANAGEMENT } from "@/store/modules.js";
import { GET_COURSE_GROUPS } from "@/store/action-types.js";
export default {
  name: "course-editor",
  props: {
    course: {},
  },
  components: {
    Subheader,
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
      },

      courseTypeOptions: [
        { text: "Grundlehrgang", value: "base" },
        { text: "Aufbaulehrgang", value: "advanced" },
        { text: "Fortbildung", value: "training" },
      ],
    };
  },
  model: {
    prop: "course",
    event: "courseChange",
  },
  computed: {
    courseLocal: {
      get: function () {
        return this.course;
      },
      set: function (value) {
        this.$emit("courseChange", value);
      },
    },
    courseGroups() {
      return this.$store.state.memberManagement.courseGroups.map((item) => {
        return {
          title: item.title,
          color: item.color,
          sortKey: item.sortKey,
          icon: item.icon,
          id: item.meta.id,
        };
      });
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$store.dispatch(`${MEMBER_MANAGEMENT}${GET_COURSE_GROUPS}`, {
        organizationId: this.$route.params.organizationId,
      });
    },
  },
};
</script>
